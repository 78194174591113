import React from "react"
import PropTypes from "prop-types"
import Button from "../button"

const Callout = ({ callout }) => {
  return (
    <div className="text-grey-darker border-l-4 border-primary py-3 pl-6 mt-8">
      <h2 className="font-semibold text-xl mb-1.5">{callout?.title}</h2>
      <p className="text-base leading-normal md:leading-relaxed mb-3 mt-0">
        {callout?.subtitle}
      </p>
      <Button
        buttonText={callout?.button_text}
        buttonType="text-base text-center font-semibold bg-secondary rounded-xl py-3 px-12 mb-1"
        handleOnClick={(e) => {
          e.preventDefault()
          window.open(`${callout?.button_link}`)
        }}
        eventName={{
          eventDomain: "App",
          eventLocation: "PrePlanning Callout",
          eventAction: "Click",
          eventActionName: "Go to Callout",
        }}
      />
    </div>
  )
}

Callout.propTypes = {
  callout: PropTypes.object.isRequired,
}

export default Callout
